import { Link as ArkLink } from '@remarkable/ark-web';
import Link from 'next/link';
import React, { useEffect } from 'react';
import { useReduxCart } from 'src/hooks/cart/useCart';
import { eventActionEnum, eventCategoryEnum, pushGAEventToDataLayer } from 'src/services/googleTagManager';
import {
  getQuantityDiscountButtonText,
  QuantityDiscount,
  trackNumberOfDevicesInCart,
} from 'src/views/Cart/quantity-discount/utils';
interface Props {
  quantityDiscount?: QuantityDiscount;
  deviceQuantity?: number;
}

export const AddQuantityDiscountButton: React.FC<Props> = ({ quantityDiscount, deviceQuantity }: Props) => {
  const cart = useReduxCart();

  useEffect(() => {
    trackNumberOfDevicesInCart(deviceQuantity);
  }, [deviceQuantity]);
  if (!quantityDiscount || !deviceQuantity || deviceQuantity < 3) return null;
  if (!quantityDiscount.currentDiscountCode && !quantityDiscount.nextDiscountCode) return null;

  const numberOfDifferentBundles = cart.data?.bundles.length ?? 0;

  function handleAddBundles(bundlesToAdd?: number) {
    if (bundlesToAdd) {
      const bundleSku = cart.data?.bundles[0]?.bundle ?? 'BUNDLE_RM110_RM212_RM314';
      cart.actions.incrementCartItemQuantity(bundleSku, bundlesToAdd);
      pushGAEventToDataLayer(
        eventCategoryEnum.CART_PAGE_NAVIGATION,
        eventActionEnum.QUANTITY_DISCOUNT,
        'Add bundles to get quantity discount from cart summary'
      );
    }
  }

  if (deviceQuantity === 24) {
    return (
      <div className="m-0 text-14">
        {'For orders over 24 devices, please '}
        <Link href="/business#form" legacyBehavior passHref>
          <ArkLink
            inline
            onClick={() => {
              pushGAEventToDataLayer(
                eventCategoryEnum.CART_PAGE_NAVIGATION,
                eventActionEnum.QUANTITY_DISCOUNT,
                'Contact sales team from cart summary'
              );
            }}
          >
            contact our sales team.
          </ArkLink>
        </Link>
      </div>
    );
  }
  if (quantityDiscount.nextDiscountCode && quantityDiscount.quantityBeforeNextDiscount) {
    return (
      <div
        data-cy="discount-content"
        className="p-20 mb-8 font-bold bg-grayscale-gray-50 text-16 lg:text-18 rounded-[4px]"
      >
        {numberOfDifferentBundles === 1 ? (
          <button
            data-cy={`add-${quantityDiscount.quantityBeforeNextDiscount}-more-device`}
            className="p-0 m-0 underline text-brand-orange-400"
            onClick={() => handleAddBundles(quantityDiscount.quantityBeforeNextDiscount)}
          >
            {getQuantityDiscountButtonText(quantityDiscount.quantityBeforeNextDiscount)}
          </button>
        ) : (
          <b>{getQuantityDiscountButtonText(quantityDiscount.quantityBeforeNextDiscount)}</b>
        )}
        &nbsp;
        <span
          data-cy={`discount-${quantityDiscount.nextDiscountCode}%`}
        >{`for a ${quantityDiscount.nextDiscountCode}% business discount`}</span>
      </div>
    );
  }
  return null;
};
