import { Types } from '@remarkable/rm-store-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { formatShippingDetails, getShippingName, getShippingTypeFromSku } from 'src/hooks/useShippingTime';
import { State } from 'src/redux/reducers';

import { usePriceFormatter } from '../../../helpers/formattingHelpers';
import { Price } from './Price';
import { Row } from './Row';

export const Shipping: React.FC<{ countryName: string; shipping: Types.Store.Cart.Shipping.Option }> = ({
  countryName,
  shipping,
}) => {
  const shippingType = getShippingTypeFromSku(shipping.sku);
  const priceFormatter = usePriceFormatter();
  const isB2bChecked = useSelector((state: State) => state.checkout.isB2bChecked);

  return (
    <Row className="space-x-24">
      <div>
        {shipping.delivery && isB2bChecked
          ? getShippingName(shipping.delivery.minDaysB2b, shippingType)
          : shipping.delivery && !isB2bChecked
          ? getShippingName(shipping.delivery.minDays, shippingType)
          : shipping.name}
        {' to '}
        <b>{countryName}</b>{' '}
        {shipping.delivery ? (
          <span className="inline-block">
            (
            {isB2bChecked
              ? formatShippingDetails(shipping.delivery.minDaysB2b, shipping.delivery.maxDaysB2b)
              : formatShippingDetails(shipping.delivery.minDays, shipping.delivery.maxDays)}
            )
          </span>
        ) : (
          ''
        )}
      </div>
      {shipping.price.amount ? (
        <Price
          text={priceFormatter(shipping.price.amount, shipping.price.currency).formatted}
          testId="total-shipping"
        />
      ) : (
        <Price text={'Included'} testId="total-shipping" />
      )}
    </Row>
  );
};
