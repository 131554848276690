import { Types } from '@remarkable/rm-store-types';
import React from 'react';
import { usePriceFormatter } from 'src/helpers/formattingHelpers';
import { QuantityDiscount } from 'src/views/Cart/quantity-discount/utils';

import BundleItem from '../BundleItem';
import Item from '../Item';
import { AddQuantityDiscountButton } from './AddQuantityDiscountButton';
import { PromotionRows } from './PromotionRows';

export const ItemsWithPromotions: React.FC<{
  bundles: Types.Store.Cart.Bundles;
  items: Types.Store.Cart.Items;
  promotionItems: { price: string; name: string }[];
  quantityDiscountProps?: {
    quantityDiscount: QuantityDiscount;
    deviceQuantity: number;
  };
  currency: string;
  withControls?: {
    editBundle: (sku: string, items: { sku: string }[]) => void;
  };
  indentContent?: boolean;
}> = ({ bundles, items, promotionItems, quantityDiscountProps, withControls, indentContent = false }) => {
  const priceFormatter = usePriceFormatter();

  return (
    <div data-cy="cart-content" className="space-y-16">
      {bundles.map((it) => (
        <BundleItem
          key={it.bundle}
          name={it.name}
          sku={it.bundle}
          quantity={it.quantity}
          image={it.image}
          unitPrice={priceFormatter(it.price.unit.amount, it.price.unit.currency).formatted}
          totalPrice={priceFormatter(it.price.value.amount, it.price.value.currency).formatted}
          items={it.items}
          withControls={withControls}
          indentContent={indentContent}
        />
      ))}
      {items.map((it) => (
        <Item
          key={it.sku}
          name={it.name}
          sku={it.sku}
          quantity={it.quantity}
          image={it.image}
          unitPrice={priceFormatter(it.price.unit.amount, it.price.unit.currency).formatted}
          totalPrice={priceFormatter(it.price.value.amount, it.price.value.currency).formatted}
          withControls={!!withControls}
          indentContent={indentContent}
        />
      ))}
      <PromotionRows promotionItems={promotionItems} indentContent={indentContent} />
      {quantityDiscountProps && (
        <AddQuantityDiscountButton
          quantityDiscount={quantityDiscountProps.quantityDiscount}
          deviceQuantity={quantityDiscountProps.deviceQuantity}
        />
      )}
    </div>
  );
};
