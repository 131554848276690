import React from 'react';
import { useReduxCart } from 'src/hooks/cart/useCart';

import { PromotionRow } from './PromotionRow';

export const PromotionRows: React.FC<{
  promotionItems: { name: string; price: string }[];
  indentContent?: boolean;
}> = ({ promotionItems, indentContent = false }) => {
  const cart = useReduxCart();
  const hasTypeFolioDiscount = !!cart.helpers.findTypeFolioInCart();

  return (
    <>
      {promotionItems.length > 0 && (
        <div className="space-y-16">
          {promotionItems.map((promotionItem) => (
            <PromotionRow
              key={promotionItem.name}
              name={promotionItem.name}
              price={promotionItem.price}
              hasTypeFolioDiscount={hasTypeFolioDiscount && promotionItem.name === 'Connect offer'}
              indentContent={indentContent}
            />
          ))}
        </div>
      )}
    </>
  );
};
