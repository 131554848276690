import { Button, Link as ArkLink } from '@remarkable/ark-web';
import { Adapters } from '@remarkable/rm-store-types';
import { CartInteraction } from 'ampli-types';
import classNames from 'classnames';
import { MinusCircle, PlusCircle, Trash } from 'phosphor-react';
import { useSelector } from 'react-redux';
import { useGTMProductsData } from 'src/contexts/GTMProductsDataContext';
import { getFormattedItemName, getItemInfo } from 'src/helpers/cartHelpers';
import { usePriceFormatter } from 'src/helpers/formattingHelpers';
import { getCountryInfoByValue } from 'src/helpers/storeHelpers';
import { useReduxCart } from 'src/hooks/cart/useCart';
import { State } from 'src/redux/reducers';
import { eventActionEnum, eventCategoryEnum, pushGAEventToDataLayer } from 'src/services/googleTagManager';
import { tracker } from 'src/services/tracker';
import { ComponentLocations } from 'src/services/tracking/eventTypes';
import { getTrackingProducts, getTrackingProductsTotal } from 'src/services/tracking/utils';
import { usePromotionBundleContext } from 'src/views/MPF/PromotionBundleContext';

type Props = {
  name: string;
  sku: string;
  image: string;
  quantity: number;
  unitPrice: string;
  totalPrice: string;
  items: any[];
  withControls?: {
    editBundle: (sku: string, items: { sku: string }[]) => void;
  };
  indentContent?: boolean;
};

const BundleItem = ({ name, sku, quantity, totalPrice, items, withControls, indentContent = false }: Props) => {
  const cart = useReduxCart();
  const country = useSelector((state: State) => state.countryDetector.country);
  const sanityCountryData = useSelector((state: State) => state.staticQuery.countryData);
  const countryData = getCountryInfoByValue(sanityCountryData, country);
  const limitReached = cart.helpers.getCartDeviceThresholdMet(countryData.deviceLimit?.limit);
  const bundlesInCart = cart.helpers.getNumberOfDevicesInCart();
  const gtmProductsData = useGTMProductsData();
  const trackingProducts = getTrackingProducts(cart.data, gtmProductsData);

  const { isValidPromotionBundle, activePromotionBundle } = usePromotionBundleContext();

  const decreaseQuantity = async () => {
    await cart.actions.decrementCartItemQuantity(sku);
  };

  const deleteItemFromCart = async () => {
    await cart.actions.deleteCartItem(sku);
  };

  const priceFormatter = usePriceFormatter();

  const chosenBundleTitle = activePromotionBundle?.bundles.find(
    (bundle) => bundle.bundleSku === sku && !bundle.configureOwn
  )?.pickerTitle;

  const title = isValidPromotionBundle(sku) ? (
    <span className="font-bold md:text-18 sm:flex sm:items-center">
      <p className="mb-8 sm:mb-0">{chosenBundleTitle ?? name}</p>
      {activePromotionBundle?.cartBadge && (
        <span className="font-book text-12 text-light bg-dark border rounded-[4px] px-8 py-[6px] sm:ml-16">
          {activePromotionBundle?.cartBadge}
        </span>
      )}
    </span>
  ) : (
    <span className="font-bold md:text-18">{name}</span>
  );

  return (
    <div className={classNames('py-16 bg-light text-dark rounded-[4px]', { 'px-16': indentContent })}>
      <header className="flex items-center justify-between mb-12">
        {title}
        {withControls && (
          <div className="flex flex-row items-center">
            <ArkLink
              data-cy="edit-cart-bundle"
              tabIndex={0}
              inline
              className="p-0 m-0 mr-20 text-12 sm:text-14 cursor-pointer"
              onClick={() => {
                pushGAEventToDataLayer(
                  eventCategoryEnum.MPF_INTERACTION,
                  eventActionEnum.BUTTON,
                  'Edit bundle from cart'
                );
                //Needed to use trackEvent to get non-string version of products
                tracker.trackEvent(
                  new CartInteraction({
                    action: 'edit bundle',
                    component_location: ComponentLocations.CART.BUNDLE,
                    cart_products: trackingProducts,
                    cart_value: getTrackingProductsTotal(trackingProducts),
                  })
                );
                withControls && withControls.editBundle(sku, items);
              }}
            >
              Edit
            </ArkLink>
            <Button
              variant="tertiary-neutral"
              aria-label="Remove item"
              data-cy="remove-cart-items"
              onClick={async () => {
                pushGAEventToDataLayer(eventCategoryEnum.MPF_INTERACTION, eventActionEnum.BUTTON, 'Delete bundle');
                deleteItemFromCart();
              }}
            >
              <Trash />
            </Button>
          </div>
        )}
      </header>
      <div className="border border-solid border-[#E0E0E0] border-r-0 border-l-0 py-16 flex items-center justify-between">
        <div className="w-full h-full">
          {items
            .sort((a, b) => Number(a.sku.replace('RM', '') - Number(b.sku.replace('RM', ''))))
            .map((it) => (
              <div key={it.sku} className="flex justify-between mb-4 space-x-16">
                <div data-cy="cart-item" data-cy-sku={`${it.sku}`} className="flex align-center">
                  <span className="p-0 my-0 mr-8 font-bold text-12 sm:text-14">
                    {getFormattedItemName(it.name)}
                    {getItemInfo(it.name) && <span className="font-book">{getItemInfo(it.name)}</span>}
                    {Adapters.Moltin.Products.isRefurbished(it) && (
                      <span
                        data-cy="refurbished-device"
                        className="font-book text-[10px] sm:text-12 border border-dark border-solid rounded-[4px] px-4 py-2 ml-8"
                      >
                        Refurbished
                      </span>
                    )}
                  </span>
                </div>
                <span className="ml-auto text-12 sm:text-14 font-demi">
                  {priceFormatter(it.price.unit.amount, it.price.unit.currency).formatted}
                </span>
              </div>
            ))}
        </div>
      </div>
      <div className="flex items-center justify-between mt-16 ml-4">
        <div className="flex items-center justify-center text-14">
          {withControls ? (
            <>
              <Button
                variant="tertiary-neutral"
                size="small"
                className="[&>svg]:!h-[28px] [&>svg]:!w-[28px]"
                aria-label="Decrease quantity by 1"
                data-cy="decrease-bundle-by-1"
                onClick={() => {
                  pushGAEventToDataLayer(
                    eventCategoryEnum.MPF_INTERACTION,
                    eventActionEnum.BUTTON,
                    'Decrease bundle quantity in cart'
                  );
                  decreaseQuantity();
                }}
                disabled={cart.loading || bundlesInCart < 2}
              >
                <MinusCircle size={28} />
              </Button>
              <p data-cy="cart-quantity" className="px-8 py-0 text-16">
                {quantity}
              </p>
              <Button
                variant="tertiary-neutral"
                size="small"
                className="[&>svg]:!h-[28px] [&>svg]:!w-[28px]"
                aria-label="Increase quantity by 1"
                data-cy="increase-bundle-by-1"
                onClick={() => {
                  pushGAEventToDataLayer(
                    eventCategoryEnum.MPF_INTERACTION,
                    eventActionEnum.BUTTON,
                    'Increase bundle quantity in cart'
                  );
                  cart.actions.incrementCartItemQuantity(sku);
                }}
                disabled={cart.loading || limitReached}
              >
                <PlusCircle size={28} />
              </Button>
            </>
          ) : (
            <p className="m-0">Quantity: {quantity}</p>
          )}
        </div>
        <span className="ml-auto font-demi text-16" data-cy="bundle-total">
          {totalPrice}
        </span>
      </div>
    </div>
  );
};

export default BundleItem;
