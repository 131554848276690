import { Adapters, Types } from '@remarkable/rm-store-types';
import stableStringify from 'json-stable-stringify';
import { useSelector } from 'react-redux';
import { formatPhoneNumber } from 'src/helpers/formattingHelpers';
import { ShippingTimeConfig } from 'src/queries/groq/shippingTimeConfig';
import { SanityCurrency } from 'src/typings/sanityTypes';
import { Logger } from 'src/utils/logger';
import useSWR, { mutate } from 'swr';

import { getCountryInfoByValue } from '../helpers/storeHelpers';
import { State } from '../redux/reducers';
import { promiseInOrder } from '../utils/promiseInOrder';
import { useReduxCart } from './cart/useCart';

// In case of any network or transport errors, these are the final fallback delivery times
const FALLBACK_SHIPPING_TIMES: DeliveryTimeEstimate = {
  express: {
    minDays: 2,
    maxDays: 8,
    delayed: false,
  },
  standard: {
    minDays: 14,
    maxDays: 28,
    delayed: false,
  },
};

const SHIPPING_ESTIMATE_API_TIMEOUT = 7000;

/* Function to check if today is weekend (friday, saturday, or sunday) */
export function isTodayWeekend(): boolean {
  const weekend = ['Fri', 'Sat', 'Sun'];
  const today = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][new Date().getDay()];
  return weekend.includes(today);
}

export function formatShippingDetails(minDays = 2, maxDays = 5): string {
  if (maxDays === 1) {
    const isWeekend = isTodayWeekend();
    if (isWeekend) {
      return `Next business day`;
    }
    return `Next day`;
  }
  if (minDays === maxDays) {
    return `${maxDays} business day${maxDays !== 1 ? 's' : ''}`;
  }
  return `${minDays}-${maxDays} business day${maxDays !== 1 ? 's' : ''}`;
}

export interface ShippingDetail {
  showNextDayDelivery: boolean;
  shippingTime: string;
  explanation: string;
  name: string;
  delayed: boolean;
}

export const getShippingTypeFromSku = (shippingMethod: string): 'standard' | 'express' => {
  return shippingMethod.toLocaleLowerCase().includes('standard') ? 'standard' : 'express';
};

export const getShippingName = (minDays: number, shippingType: string): string => {
  if (shippingType === 'express') {
    if (minDays > 9) return 'Shipping';
    return 'Express shipping';
  }
  return 'Standard shipping';
};

/** @deprecated */
export const useShippingTimeDeprecated = (
  country: string,
  skus: string[],
  shippingMethod: string,
  sanityShippingTimeConfig: ShippingTimeConfig
): ShippingDetail => {
  const { delays, expressShipping, standardShipping, nextDayDelivery } = sanityShippingTimeConfig;
  const shippingType = getShippingTypeFromSku(shippingMethod);
  const shippingTimes = shippingType === 'express' ? expressShipping : standardShipping;
  const shippingTimesForCountry =
    Array.isArray(shippingTimes) &&
    shippingTimes.find((shippingTimeGroup: { countries: string | string[] }) =>
      shippingTimeGroup.countries.includes(country || '')
    );

  // Default values if we can't find shipping times in config
  let minDays = 2;
  let maxDays = 5;
  if (shippingTimesForCountry) {
    minDays = shippingTimesForCountry.min_days;
    maxDays = shippingTimesForCountry.max_days;
  }
  let minAddition = 0;
  let maxAddition = 0;
  let minAdditionB2b = 0;
  let maxAdditionB2b = 0;
  let explanation = '';
  let delayed = false;
  if (Array.isArray(delays)) {
    delays.forEach((delay) => {
      if (delay[shippingType] && skus.filter((sku) => delay.products.includes(sku)).length > 0) {
        if (
          minAddition <= delay.min_days ||
          maxAddition <= delay.max_days ||
          minAdditionB2b <= delay.min_days ||
          maxAdditionB2b <= delay.max_days
        ) {
          minAddition = delay.min_days;
          maxAddition = delay.max_days;
          minAdditionB2b = delay.min_days_b2b;
          maxAdditionB2b = delay.max_days_b2b;
          delayed = true;
          explanation = delay.explanation || '';
        }
      }
    });
  }
  minDays += minAddition;
  maxDays += maxAddition;
  const name = getShippingName(minDays, shippingType);
  return {
    showNextDayDelivery: !!nextDayDelivery && minDays === 1,
    shippingTime: formatShippingDetails(minDays, maxDays),
    explanation,
    name,
    delayed,
  };
};

// TODO: move this and DeliveryTimeEstimate to rm-store-types
export interface DeliveryTime {
  minDays: number;
  maxDays: number;
  delayed: boolean;
}

export interface DeliveryTimeEstimate {
  express: DeliveryTime;
  standard: DeliveryTime;
}

async function fetchShippingTimes(payload: ShippingConfig): Promise<DeliveryTimeEstimate> {
  return promiseInOrder('shippingTimeFetcher', async () => {
    const timeoutController = new window.AbortController();
    const requestTimeout = window.setTimeout(() => timeoutController.abort(), SHIPPING_ESTIMATE_API_TIMEOUT);
    const url = `${process.env.NEXT_PUBLIC_RM_STORE_API_URL}/delivery-times/estimate`;
    const res = await window.fetch(url, {
      method: 'POST',
      body: stableStringify(payload),
      headers: { 'Content-Type': 'application/json' },
      signal: timeoutController.signal,
    });
    window.clearTimeout(requestTimeout);
    if (!res.ok) {
      throw Logger.warn({
        category: Logger.Category.API_REQUEST,
        message: `Shipping times API request failed with status ${res.status}`,
        context: {
          response: await res.json(),
        },
      });
    }
    return await res.json();
  });
}

export type UseShippingTimesHook =
  | {
      loading: false;
      shippingTime: string;
      explanation: string;
      name: string;
      delayed: boolean;
      showNextDayDelivery: boolean;
      minDays: number;
      maxDays: number;
    }
  | {
      loading: true;
      shippingTime: null;
      explanation: null;
      name: null;
      delayed: false;
      showNextDayDelivery: false;
      minDays: null;
      maxDays: null;
    };

type ProductWithQuantity = {
  sku: string;
  quantity: number;
};

export type ShippingConfig = {
  order_type: 'sales_b2b' | 'sales_b2c';
  currency: {
    code: string;
    exponent: number;
  };
  import_id: string | undefined;
  eori: undefined;
  delivery_address: {
    first_name: string | undefined;
    last_name: string | undefined;
    company_name: string | undefined;
    email: string | undefined;
    phone: string | undefined;
    line_1: string | undefined;
    line_2: string | undefined;
    line_3: string | undefined;
    city: string | undefined;
    region: string | undefined;
    postcode: string | undefined;
    country: string;
  };
  lines: ProductWithQuantity[];
};

type ShippingTypes = 'express' | 'standard';

const useShippingTimeData = (shippingConfig: ShippingConfig) => {
  return useSWR<DeliveryTimeEstimate>([stableStringify(shippingConfig)], () => fetchShippingTimes(shippingConfig), {
    revalidateOnFocus: false,
    revalidateOnMount: true,
    refreshInterval: undefined,
    dedupingInterval: Infinity,
    loadingTimeout: SHIPPING_ESTIMATE_API_TIMEOUT,
    onLoadingSlow(key) {
      mutate(key, FALLBACK_SHIPPING_TIMES, { revalidate: false });
    },
    onError(err, key) {
      Logger.warn({
        category: Logger.Category.API_REQUEST,
        message: 'API call to estimate delivery times failed',
        context: {
          err: err,
          shippingConfig,
        },
        exception: err,
      });
      mutate(key, FALLBACK_SHIPPING_TIMES, { revalidate: false });
    },
    errorRetryCount: 5,
    errorRetryInterval: 500,
  });
};

const useReduxCartShippingConfig = (): ShippingConfig => {
  const cart = useReduxCart();
  const checkoutDetails = useSelector((state: State) => state.checkout);
  const countryDetector = useSelector((state: State) => state.countryDetector);
  const sanityCountryData = useSelector((state: State) => state.staticQuery.countryData);
  const countryData = getCountryInfoByValue(sanityCountryData, countryDetector.country);

  const shippingConfig: ShippingConfig = {
    order_type: checkoutDetails.isB2bChecked ? 'sales_b2b' : 'sales_b2c',
    currency: {
      code: countryData.currency.value,
      exponent: countryData.currency.exponent,
    },
    import_id: checkoutDetails.shippingAddress.VAT?.value || undefined,
    eori: undefined, // Not yet implemented
    delivery_address: {
      first_name: checkoutDetails.shippingAddress.firstName?.value || undefined,
      last_name: checkoutDetails.shippingAddress.lastName?.value || undefined,
      company_name: checkoutDetails.shippingAddress.company?.value || undefined,
      email: checkoutDetails.customer.email?.value || undefined,
      phone:
        checkoutDetails.customer.phoneNumber?.value && checkoutDetails.customer.phoneNumberCountryCode.value
          ? formatPhoneNumber(
              checkoutDetails.customer.phoneNumberCountryCode.value,
              checkoutDetails.customer.phoneNumber.value
            )
          : undefined || undefined, // TODO: check this formatting
      line_1: checkoutDetails.shippingAddress.addressLine1?.value || undefined,
      line_2: checkoutDetails.shippingAddress.addressLine2?.value || undefined,
      line_3: undefined, // Not yet implemented
      city: checkoutDetails.shippingAddress.city?.value || undefined,
      region: checkoutDetails.shippingAddress.state?.value || undefined,
      postcode: checkoutDetails.shippingAddress.zipCode?.value || undefined,
      country: countryDetector.country,
    },
    lines: cart.helpers
      .getAllItemsInCart()
      .filter(Adapters.Moltin.Products.isPhysical)
      .map((item) => ({
        sku: item.sku,
        quantity: item.quantity,
      })),
  };

  return shippingConfig;
};

const useShippingTimes = (shippingType: ShippingTypes, shippingConfig: ShippingConfig): UseShippingTimesHook => {
  const data = useShippingTimeData(shippingConfig);
  const shippingTime = data.data?.[shippingType];

  return !shippingTime
    ? {
        loading: true,
        showNextDayDelivery: false,
        delayed: false,
        explanation: null,
        name: null,
        shippingTime: null,
        minDays: null,
        maxDays: null,
      }
    : {
        loading: false,
        showNextDayDelivery: false,
        delayed: shippingTime.delayed,
        explanation: '',
        name: getShippingName(shippingTime.minDays, shippingType),
        shippingTime: formatShippingDetails(shippingTime.minDays, shippingTime.maxDays),
        minDays: shippingTime.minDays,
        maxDays: shippingTime.maxDays,
      };
};

export const useEstimatedShippingTimes = (
  shippingType: ShippingTypes,
  currencyDetails: SanityCurrency,
  country: string,
  products: ProductWithQuantity[]
) => {
  const shippingConfig: ShippingConfig = {
    currency: { code: currencyDetails.value, exponent: currencyDetails.exponent },
    delivery_address: {
      first_name: undefined,
      last_name: undefined,
      company_name: undefined,
      email: undefined,
      phone: undefined,
      line_1: undefined,
      line_2: undefined,
      line_3: undefined,
      city: undefined,
      region: undefined,
      postcode: undefined,
      country: country,
    },
    eori: undefined,
    import_id: undefined,
    order_type: 'sales_b2c',
    lines: products,
  };

  return useShippingTimes(shippingType, shippingConfig);
};

export function useReduxCartShippingTimes(shippingType: ShippingTypes): UseShippingTimesHook {
  const shippingConfig = useReduxCartShippingConfig();
  return useShippingTimes(shippingType, shippingConfig);
}

// TODO: Clean this up after we don't need the new and old shipping estimate logic in parallel
/** @deprecated */
export function useDeprecatedSelectedShippingOption(cart: Types.Store.Cart | undefined) {
  const useNewShipping = true;
  const standardShippingTime = useReduxCartShippingTimes('standard');
  const expressShippingTime = useReduxCartShippingTimes('express');
  const shippingTimeNew =
    getShippingTypeFromSku(cart?.shipping.selected?.sku ?? 'express') === 'express'
      ? expressShippingTime
      : standardShippingTime;
  const shippingOptionNew =
    !cart?.shipping.selected || shippingTimeNew.loading
      ? undefined
      : {
          ...cart?.shipping.selected,
          delivery: {
            delayed: shippingTimeNew.delayed,
            explanation: shippingTimeNew.explanation,
            minDays: shippingTimeNew.minDays,
            maxDays: shippingTimeNew.maxDays,
            minDaysB2b: shippingTimeNew.minDays,
            maxDaysB2b: shippingTimeNew.maxDays,
          },
        };
  const selectedShippingOption: Types.Store.Cart.Shipping.Option | undefined = useNewShipping
    ? shippingOptionNew
    : cart?.shipping.selected;
  return selectedShippingOption;
}
