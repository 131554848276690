import React from 'react';

export const Price: React.FC<{ text: string; highlight?: boolean; testId?: string }> = ({
  text: price,
  highlight,
  testId,
}) => (
  <div className={`font-bold text-right ${highlight ? ' text-brand-orange-400' : ''}`} data-cy={testId}>
    {price}
  </div>
);
