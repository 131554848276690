import { Types } from '@remarkable/rm-store-types';
import { NextRouter } from 'next/router';
import { GTMProductsData } from 'src/contexts/GTMProductsDataContext';
import { clearCartId } from 'src/hooks/cart/util';
import { State as CheckoutReducerState } from 'src/redux/reducers/checkoutReducer';
import { getShouldOmitVATFromTracking, trackPurchase } from 'src/services/googleTagManager';
import * as RmStoreAPI from 'src/services/rm-store-api';
import * as RmStoreAPIHelpers from 'src/services/rm-store-api/helpers';
import { trackCheckoutError } from 'src/services/tracking/trackEventMethods';
import { Logger } from 'src/utils/logger';

import { getUTMParams, UTMParams } from './campaignHelpers';
import { navigateToOrderConfirmation } from './checkoutHelpers';
import { getCurrencyForCountry } from './formattingHelpers';
import { getCurrentCountryDataEntry, getVATPercentageFromCountryDataEntry } from './storeHelpers';

export function isKlarnaSupportedCountry(country: string): boolean {
  const supportedCountries = ['NO', 'DE', 'FI', 'GB'];
  return supportedCountries.includes(country);
}

export function isAffirmSupportedCountry(country: string): boolean {
  const supportedCountries: string[] = ['US'];
  return supportedCountries.includes(country);
}

// /////////////////
// Payment
// /////////////////
interface FinishUpPaymentSuccessProps {
  checkoutResponse: Types.Moltin.Order.GET.Response;
  cart: Types.Store.Cart;
  paymentMethod: Types.Moltin.Order.Payment.Methods;
}

export function finishUpPaymentSuccess(props: FinishUpPaymentSuccessProps, gtmProductsData: GTMProductsData): boolean {
  const { checkoutResponse, cart } = props;
  const orderId = checkoutResponse.data?.id;
  const shippingAddress = checkoutResponse.data?.shipping_address;

  if (!orderId || !shippingAddress) {
    return false;
  }

  //Is there any scenario where this would not be true? If payment goes through we would want to know this in Amplitude
  //We do not want to use user-info in Amplitude, so this info is irrelevant in that regard.
  if (shippingAddress) {
    const currencyDetails = getCurrencyForCountry(shippingAddress.country);
    const shouldOmitVATFromTracking = getShouldOmitVATFromTracking();
    const VATPercentage = getVATPercentageFromCountryDataEntry(
      getCurrentCountryDataEntry(shippingAddress.country),
      shippingAddress.county
    );

    trackPurchase({
      moltinCheckoutResponse: checkoutResponse,
      cart,
      orderId,
      country: shippingAddress.country,
      currencyDetails,
      VATPercentage,
      shouldOmitVATFromTracking,
      county: shippingAddress.county,
      gtmProductsData,
      paymentMethod: props.paymentMethod,
    });
  }

  const utmParams: UTMParams = getUTMParams();
  const campaignId = utmParams.utm_campaign;

  if (campaignId) {
    RmStoreAPI.addCampaignIdToOrder(orderId, campaignId);
  }

  clearCartId();
  return true;
}

type StripePaymentProps = {
  router: NextRouter;
  orderDetails: CheckoutReducerState;
  cart: Types.Store.Cart;
};

// TODO: This order flow needs to be updated now that we checkout EP cart before payment is collected.
// There is no need to checkout again here...
export async function invoicePayment(props: StripePaymentProps, gtmProductsData: GTMProductsData): Promise<void> {
  const { cart, orderDetails } = props;

  let orderId: string;
  try {
    const checkoutCartBody = RmStoreAPIHelpers.formatCheckoutDetails(orderDetails);
    const checkoutResponse: any = await RmStoreAPI.checkoutCart(
      { ...checkoutCartBody, pay_by_invoice: true },
      cart.currency
    );
    orderId = checkoutResponse.data.id;

    await RmStoreAPI.authorizePayment(orderId);
    finishUpPaymentSuccess(
      { checkoutResponse, cart, paymentMethod: Types.Moltin.Order.Payment.Methods.Invoice },
      gtmProductsData
    );
    navigateToOrderConfirmation({
      router: props.router,
      receiptType: 'invoice',
      orderId: checkoutResponse.data?.id,
    });
  } catch (error: any) {
    Logger.warn({
      category: Logger.Category.CHECKOUT,
      exception: error,
      message: error.message ?? 'Invoice checkout failed',
      context: {
        cart,
        orderDetails,
      },
    });
    trackCheckoutError('Invoice checkout failed', error);
  }
}
