import { Types } from '@remarkable/rm-store-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { State } from 'src/redux/reducers';
import { unreachableCode } from 'src/utils/unreachableCode';

import { Price } from './Price';
import { Shipping } from './Shipping';
import { TaxStatus } from './types';

type ShippingOption = Types.Store.Cart.Shipping.Option;

export type Props = {
  totalPrice: string;
  subtotalPrice?: string;
  countryName: string;
  taxStatus: TaxStatus;
  formattedTaxPrice: string;
  exclusiveVat?: string; // TODO: uncertain if we need this but it was in the old OrderConfirmationContent
  shipping?: ShippingOption | React.ReactChild; // TODO: we use this to inject other shipping pickers, but not sure we need them?
  totalSavings?: string;
};

const taxMessage = (taxStatus: TaxStatus, formattedPrice: string) => {
  switch (taxStatus.type) {
    case 'address-required':
      return 'Calculated with shipping address';
    case 'included':
      return 'Included';
    case 'calculated-in-checkout':
      return 'Calculated in checkout';
    case 'required':
      return formattedPrice;
    default:
      return unreachableCode(`Cannot render tax status: ${JSON.stringify(taxStatus)}`, taxStatus);
  }
};

const taxRatesForCanada = (province) => {
  switch (province) {
    case 'AB':
      return '5% GST';
    case 'BC':
    case 'MB':
      return '7% PST and 5% GST';
    case 'NT':
    case 'NU':
    case 'YT':
      return '5% GST';
    case 'ON':
      return '13% HST';
    case 'QC':
      return '9.975% PST and 5% GST';
    case 'SK':
      return '6% PST and 5% GST';
    default:
      return '15% HST';
  }
};

const BorderLight = () => <div className="my-16 border-0 border-t border-solid border-grayscale-gray-500" />;

const Row: React.FC<{ children: [React.ReactChild, React.ReactChild] }> = ({ children }) => (
  <div className="flex justify-between">{children}</div>
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isShippingOption(shipping: any): shipping is ShippingOption {
  return shipping?.sku && shipping?.name && shipping?.price;
}

const ShippingAndTax = ({ countryName, taxStatus, shipping, formattedTaxPrice }) => {
  const state = useSelector((state: State) => state.countryDetector.region);
  return (
    <>
      {isShippingOption(shipping) ? <Shipping countryName={countryName} shipping={shipping} /> : shipping}
      {taxStatus && (
        <Row>
          <div data-cy="taxes-duties-label">
            Taxes and duties to <b>{countryName}</b>{' '}
            {state && countryName === 'Canada' && `(${taxRatesForCanada(state)})`}
          </div>

          <span data-cy="total-taxes">
            <Price text={taxMessage(taxStatus, formattedTaxPrice)} />
          </span>
        </Row>
      )}
    </>
  );
};

export const SummaryLines: React.FC<Props> = ({
  totalPrice,
  subtotalPrice,
  countryName,
  taxStatus,
  formattedTaxPrice,
  shipping,
  exclusiveVat,
  totalSavings,
}) => (
  <div className="mb-40 space-y-16 pt-16 text-dark">
    {subtotalPrice && (
      <ShippingAndTax
        countryName={countryName}
        taxStatus={taxStatus}
        shipping={shipping}
        formattedTaxPrice={formattedTaxPrice}
      />
    )}
    <BorderLight />
    <Row>
      <div className="text-24 font-bold">Total</div>
      <div data-cy="order-total" className="text-24 font-bold">
        {totalPrice}
      </div>
    </Row>
    {!subtotalPrice && <BorderLight />}
    {exclusiveVat && (
      <Row>
        <div>Excl import VAT:</div>
        <Price text={exclusiveVat} />
      </Row>
    )}
    {totalSavings && (
      <Row>
        <div className="font-bold">Total savings</div>
        <span data-cy="total-savings">
          <Price text={totalSavings} highlight />
        </span>
      </Row>
    )}
    {!subtotalPrice && (
      <ShippingAndTax
        countryName={countryName}
        taxStatus={taxStatus}
        shipping={shipping}
        formattedTaxPrice={formattedTaxPrice}
      />
    )}
  </div>
);
