import { NextRouter } from 'next/router';
import queryString from 'querystring';

export type ReceiptType = 'stripe' | 'redirected' | 'paypal' | 'invoice';

export function navigateToOrderConfirmation({
  router,
  receiptType,
  orderId,
  async = false,
}: {
  router: NextRouter;
  receiptType: ReceiptType;
  orderId: string;
  async?: boolean;
}) {
  router.push(
    `/store/order-confirmation?${queryString.stringify({
      type: receiptType,
      ...(orderId && { orderId }),
      ...(async && { async: true }),
    })}`
  );
}
