import { Button } from '@remarkable/ark-web';
import { Adapters } from '@remarkable/rm-store-types';
import classNames from 'classnames';
import { MinusCircle, PlusCircle, Trash } from 'phosphor-react';
import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { getCountryInfoByValue } from 'src/helpers/storeHelpers';
import { useReduxCart } from 'src/hooks/cart/useCart';
import { State } from 'src/redux/reducers';

type Props = {
  name: string;
  sku: string;
  image: string;
  quantity: number;
  unitPrice: string;
  totalPrice: string;
  withControls?: boolean;
  indentContent?: boolean;
};

const Item: FunctionComponent<Props> = ({
  name,
  sku,
  image,
  quantity,
  unitPrice,
  totalPrice,
  withControls = true,
  indentContent = false,
}) => {
  const cart = useReduxCart();
  const isDevice = Adapters.Moltin.Products.isDevice({ sku });
  const country = useSelector((state: State) => state.countryDetector.country);
  const sanityCountryData = useSelector((state: State) => state.staticQuery.countryData);
  const countryData = getCountryInfoByValue(sanityCountryData, country);
  const limitReached = isDevice && cart.helpers.getCartDeviceThresholdMet(countryData.deviceLimit?.limit);

  return (
    <div
      data-cy="cart-item"
      data-cy-sku={`${sku}`}
      className={classNames('py-16 bg-grayscale-gray-50 rounded', { 'px-16': indentContent })}
    >
      <header className="flex flex-row items-center justify-between mb-12">
        <div className="flex flex-row items-center font-demi">{name}</div>

        {withControls && (
          <button className="h-[32px]" aria-label="Remove item" onClick={() => cart.actions.deleteCartItem(sku)}>
            <Trash weight="regular" size={32} />
          </button>
        )}
      </header>
      <div className="flex items-center py-16 border-0 border-y border-solid border-[#E0E0E0]">
        <img src={`${image}?h=100`} width="85px" />
        <p className="mx-16 text-12 sm:text-14">{name}</p>
        <span className="ml-auto text-12 sm:text-14 font-demi">{unitPrice}</span>
      </div>

      <div className="flex items-center justify-between pt-20 pb-12">
        <div className="flex items-center">
          {withControls ? (
            <>
              <Button
                variant="tertiary-neutral"
                size="small"
                className="[&>svg]:!h-[28px] [&>svg]:!w-[28px]"
                aria-label="Decrease quantity by 1"
                data-cy="decrease-item-by-1"
                onClick={() => cart.actions.decrementCartItemQuantity(sku)}
                disabled={cart.loading}
              >
                <MinusCircle size={28} />
              </Button>
              <p className="px-8 m-0 text-16">{quantity}</p>
              <Button
                variant="tertiary-neutral"
                size="small"
                className="[&>svg]:!h-[28px] [&>svg]:!w-[28px]"
                aria-label="Increase quantity by 1"
                data-cy="increase-item-by-1"
                onClick={() => cart.actions.incrementCartItemQuantity(sku)}
                disabled={cart.loading || limitReached}
              >
                <PlusCircle size={28} />
              </Button>
            </>
          ) : (
            <p style={{ margin: 0 }}>Quantity: {quantity}</p>
          )}
        </div>
        <span className="ml-auto font-demi text-16">{totalPrice}</span>
      </div>
    </div>
  );
};

export default Item;
