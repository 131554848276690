import classNames from 'classnames';
import React from 'react';
import TagIcon from 'src/assets/Icons/icon_tag.svg';
import DividerLine from 'src/components/TechnicalSpecificationsList/DividerLine';

import { Price } from './Price';
import { Row } from './Row';

export const PromotionRow: React.FC<{
  name: string;
  price: string;
  hasTypeFolioDiscount: boolean;
  indentContent?: boolean;
}> = ({ name, price, hasTypeFolioDiscount, indentContent = false }) => (
  <div className={classNames('py-20 bg-grayscale-gray-50 rounded-[4px]', { 'px-20': indentContent })}>
    <Row>
      <div
        className="flex items-center justify-center space-x-16 font-bold text-dark text-16 lg:text-18"
        data-cy="promotion-item"
      >
        <TagIcon />
        <span>{name}</span>
      </div>
      <Price text={price} />
    </Row>
    {hasTypeFolioDiscount && (
      <>
        <DividerLine />
        <span className="text-14">
          <b>One-time offer. </b>This offer is only applied to one Type Folio per customer. Cannot be combined with
          other discounts on accessories.
        </span>
      </>
    )}
  </div>
);
